<template>
  <div @click="dzDelJs">
    <div class="content" ref="myBox">
      <div class="contentBox" ref="myPage">
        <div class="shopHeadBox">
          <div class="img">
            <img src="../../assets/img/logo-p.png" alt="" />
          </div>
          <div class="inpBox">
            <input
              type="text"
              placeholder="请输入关键词搜索"
              v-model="seachName"
              @keyup.enter="searchClick"
            />
            <div class="searchBtn" @click="searchClick">搜索</div>
          </div>
        </div>
        <div class="nrCont">
          <div class="nrHead">
            <div class="leftBox">
              <div class="imgs scroll">
                <div
                  class="img"
                  :class="index == imgOn ? 'on' : ''"
                  v-for="(item, index) in resources"
                  @mouseenter="mouseover(index, item.path_name)"
                >
                  <img :src="$imgUrl + item.path_name" alt="" srcset="" />
                </div>
              </div>
              <div class="imgBig">
                <img v-if="infoImg" :src="$imgUrl + infoImg" alt="" srcset="" />
              </div>
            </div>
            <div class="rightBox" :class="cpShow">
              <div class="rtitle">{{ info.name }}</div>
              <div class="rtxt">已售 {{ info.sales }}+</div>
              <div class="money">
                <div class="txt">￥</div>
                <div class="num">{{ info.price }}</div>
              </div>
              <div class="box">
                <div class="name">配送:</div>
                <div class="text">
                  <div class="wz">台州路桥</div>
                  <div class="zgi">至</div>
                  <div class="wz" @click.stop="dzShowJs">
                    {{
                      addressInfo.id
                        ? addressInfo.city + addressInfo.area
                        : "选择收货地"
                    }}
                    <div class="iconfont icon-unfold" :class="xuanzhuan"></div>
                  </div>
                </div>
              </div>
              <div class="dzBox" v-if="dzShow">
                <div class="dzCont">
                  <div class="dzHead">
                    <div class="name">选择配送地址</div>
                    <div class="iconfont icon-close" @click="dzDelJs"></div>
                  </div>
                  <div class="dzList scroll">
                    <div
                      class="dzLi"
                      :class="index == addressOn ? 'on' : ''"
                      v-for="(item, index) in addresslist"
                      @click="addresslistClick(item, index)"
                    >
                      <div class="mr" v-if="item.is_default == 1">默认</div>
                      <div class="name">
                        {{ item.name }}<span>{{ item.mobile }}</span>
                      </div>
                      <div class="txt c-2">
                        {{ item.province }}{{ item.city }}{{ item.area
                        }}{{ item.address }}
                      </div>
                    </div>
                    <div class="dzLi" @click="addressshow = true">
                      <div class="dzLiBox dis-cent">
                        <div class="iconfont icon-add"></div>
                        <div class="name">新增地址</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box">
                <div class="name">保障:</div>
                <div class="text">15天退货</div>
              </div>
              <!-- <a-button type="" @click="handleQuickSetSkuStock111"
                >测试数据</a-button
              > -->
              <a-form v-if="attrList.length != 0">
                <!-- <h1>商品属性选择</h1> -->
                <a-form-item
                  v-for="(attrItem, rowIndex) in attrList"
                  :label="attrItem.attrLabel"
                  :key="attrItem.attrLabel"
                >
                  <a-space>
                    <a-button
                      v-for="(option, colIndex) in attrItem.options"
                      :key="option.value"
                      @click="handleCheck(rowIndex, colIndex)"
                      :disabled="option.disabled"
                      :type="option.isChecked ? 'primary' : 'default'"
                      >{{ option.value }}</a-button
                    >
                  </a-space>
                </a-form-item>
              </a-form>
              <!-- <div class="box1" v-for="(item, index) in ggList">
                <div class="name">{{ item.name }}</div>
                <div class="txt">:</div>
                <div class="txts">
                  <div
                    class="text"
                    :class="{
                      on: chiIndex == item.select,
                      hui: chi.pd,
                    }"
                    @click="ggClick(index, chiIndex, item, chi)"
                    v-for="(chi, chiIndex) in item.child"
                  >
                    {{ chi.value }}
                  </div>
                </div>
              </div> -->
              <div class="box2">
                <div class="name">数量:</div>
                <div class="numBox">
                  <div
                    class="an"
                    :class="num <= 1 ? 'jy' : ''"
                    @click="jianClick"
                  >
                    <div class="iconfont icon-jian1"></div>
                  </div>
                  <div class="num">
                    <input type="text" v-model="num" @blur="handleBlur" />
                  </div>
                  <div class="an1" @click="addClick">
                    <div class="iconfont icon-add"></div>
                  </div>
                </div>
              </div>
              <div class="funBox">
                <div class="fun" @click="shopClick">立即购买</div>
                <div class="fun1" @click="gwcClick">加入购物车</div>
              </div>
            </div>
          </div>
          <div class="typeList">
            <div
              class="type"
              :class="index == typeOn ? 'on' : ''"
              v-for="(item, index) in typeList"
              @click="typeClick(item, index)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="nrContent" id="section">
            <div class="nrtitle">用户评价</div>
            <div class="pjList">
              <Kong v-if="yonghu.length == 0" />
              <div class="pjLi" v-for="item in yonghu">
                <div class="img">
                  <img :src="$imgUrl + item.memberavatar" alt="" />
                </div>
                <div class="pjLiBox">
                  <div class="pjHead">
                    <div class="name">{{ item.membername }}</div>
                    <div class="txt">
                      <div class="t">{{ item.time }}</div>
                      <div class="d">·</div>
                      <div class="star">
                        <div
                          class="iconfont icon-collection_fill"
                          v-for="chr in item.score"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="pjTxt">{{ item.content }}</div>
                  <div class="pjImgs">
                    <div
                      class="pjimg"
                      v-for="(chr, index) in item.resources"
                      @click="imgClick(item.resources, index)"
                    >
                      <img :src="$imgUrl + chr.path_name" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pjAnBox" v-if="yonghu != 0">
              <div class="pjAn" @click="plShow = true">
                全部评价<span class="iconfont icon-enter"></span>
              </div>
            </div>
          </div>
          <div class="nrContent" id="section">
            <div class="nrtitle">参数信息</div>
            <div class="csCont">
              <div class="csLi">
                <div class="name">工艺</div>
                <div class="text">{{ info.technology }}</div>
              </div>
              <div class="csLi">
                <div class="name">机器类型</div>
                <div class="text">{{ info.model_id }}</div>
              </div>
              <div class="csLi">
                <div class="name">长度</div>
                <div class="text">{{ info.longs }}</div>
              </div>
              <div class="csLi">
                <div class="name">外径</div>
                <div class="text">{{ info.wai }}</div>
              </div>
              <div class="csLi">
                <div class="name">内径</div>
                <div class="text">{{ info.nei }}</div>
              </div>
              <div class="csLi">
                <div class="name">高度</div>
                <div class="text">{{ info.height }}</div>
              </div>
              <div class="csLi">
                <div class="name">适用情况</div>
                <div class="text">{{ info.suit ? info.suit : "无" }}</div>
              </div>
              <div class="csLi">
                <div class="name">压脚功能</div>
                <div class="text">{{ info.foot ? info.foot : "无" }}</div>
              </div>
            </div>
          </div>
          <div class="nrContent" id="section">
            <div class="nrtitle">图文详情</div>
            <div class="twCont" v-html="info.content"></div>
          </div>
          <div
            class="fuCont"
            :class="fuShow"
            :style="'right:' + (leftnum + 30) + 'px;'"
          >
            <div class="fuBox">
              <div class="fuBoxHead">
                <div class="img">
                  <img :src="$imgUrl + infoImg" alt="" />
                </div>
                <div class="box">
                  <div class="money">
                    <div class="txt">￥</div>
                    <div class="num">{{ info.price }}</div>
                  </div>
                  <div class="text c-1">{{ info.modelname }}</div>
                </div>
              </div>
              <div class="fuBoxList scroll">
                <!-- <div class="fuBoxLi">
                  <div class="nameBox">
                    <div class="name">规格</div>
                    <div class="txt">:</div>
                  </div>
                  <div class="boxs">
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                    <div class="box c-1">机器机器机器机器机器机器机器机器</div>
                  </div>
                </div> -->
                <div class="fuBoxLi">
                  <a-form v-if="attrList.length != 0">
                    <!-- <h1>商品属性选择</h1> -->
                    <a-form-item
                      v-for="(attrItem, rowIndex) in attrList"
                      :label="attrItem.attrLabel"
                      :key="attrItem.attrLabel"
                    >
                      <a-space>
                        <a-button
                          v-for="(option, colIndex) in attrItem.options"
                          :key="option.value"
                          @click="handleCheck(rowIndex, colIndex)"
                          :disabled="option.disabled"
                          :type="option.isChecked ? 'primary' : 'default'"
                          >{{ option.value }}</a-button
                        >
                      </a-space>
                    </a-form-item>
                  </a-form>
                </div>
                <div class="fuBoxLi">
                  <div class="nameBox">
                    <div class="name">数量</div>
                    <div class="txt">:</div>
                  </div>
                  <div class="numBox">
                    <div class="an">
                      <div class="iconfont icon-jian1" @click="jianClick"></div>
                    </div>
                    <div class="num">
                      <input type="text" v-model="num" />
                    </div>
                    <div class="an1">
                      <div class="iconfont icon-add" @click="addClick"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fuBtn">
              <div class="box" @click="shopClick">立即购买</div>
              <div class="box" @click="gwcClick">加入购物车</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ImgBig
      @del="imgDelClick"
      :list="imgList"
      :index="imgIndex"
      v-if="imgShow"
    />
    <PlCont @del="plDelClick" :id="id" v-if="plShow" />
    <TjDizhi v-if="addressshow" @show="addressShowDelJs" />
    <a-modal v-model="visible" title="提示" @ok="handleOk">
      <p>是否确定立即购买</p>
    </a-modal>
    <FunBox :num="gwNum" />
  </div>
</template>

<script>
import FunBox from "../component/funBox.vue";
import { Number } from "core-js";
import ImgBig from "../component/imgBig.vue";
import PlCont from "../component/plCont.vue";
import TjDizhi from "../component/tjDizhi.vue";
import Kong from "../component/kong.vue";
import {
  setAttrOptionStatus,
  computedSkuResultMap,
  createSkuList,
  handleQuickSetSkuStock,
} from "./shopCont2";
import { Form, Space, Button, Modal } from "ant-design-vue";

export default {
  components: {
    Kong,
    TjDizhi,
    ImgBig,
    PlCont,
    FunBox,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-button": Button,
    "a-space": Space,
    "a-modal": Modal,
  },
  name: "",
  data() {
    return {
      imgIndex: 0,
      imgList: [],
      gwNum: 0,
      visible: false,
      addressshow: false,
      computedSkuResultMap,
      plShow: false,
      imgShow: false,
      imgOn: null,
      num: 1,
      scrollTop: 0,
      cpShow: "",
      pd: 0,
      typeOn: 0,
      typeList: [
        {
          name: "用户评价",
          id: "#section1",
        },
        {
          name: "参数信息",
          id: "#section2",
        },
        {
          name: "图文详情",
          id: "#section3",
        },
      ],
      scopd: 0,
      leftnum: 0,
      fuShow: "",
      time: "",
      dzShow: false,
      xuanzhuan: "",
      seachName: "",
      info: {},
      infoImg: "",
      resources: [],
      ggList: [],
      dianjiNum: 0,
      attrList: [],
      skuList: [],
      skuMap: {},
      addressInfo: {},
      addresslist: [],
      addressOn: 0,
      yonghu: [],
      id: 0,
    };
  },
  watch: {
    skuList(val) {
      this.skuMap = computedSkuResultMap(val);
      this.attrList = setAttrOptionStatus(this.attrList, this.skuMap);
    },
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.get();
    this.$nextTick(() => {
      document.addEventListener("scroll", this.handleScroll);
      let navContents = this.$el.querySelectorAll("#section");
      const offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop); // 获取上面DOM元素距离顶部的距离
      });
      this.offsetTopArr = offsetTopArr;
      window.addEventListener("beforeunload", (e) => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
    });
    // ref=myBox元素
    let da = this.$refs.myBox.offsetWidth;
    let xiao = this.$refs.myPage.offsetWidth;
    this.leftnum = (da - xiao) / 2;
    this.evaluation_gets();
    this.address_default_get();
    this.addressList();
  },
  methods: {
    handleOk() {
      let list = [];
      let spec_id = "";
      let data = {};
      if (this.moPdList) {
        for (let i = 0; i < this.attrList.length; i++) {
          const e = this.attrList[i];
          if (e.currentValue) {
            list.push(e.currentValue);
          }
        }
        list = list.join("_");
        for (let i = 0; i < this.moPdList.length; i++) {
          const e = this.moPdList[i];
          if (e.name == list) {
            spec_id = e.id;
          }
        }
        data.spec_id = spec_id;
      }
      if (this.moPdList.length != 0 && !spec_id) {
        this.$message.warning("请选择规格");
        return;
      }
      data.part_id = Number(this.id);
      data.num = this.num;
      this.$post("/web/car_now", data).then((res) => {
        if (res.data.status == 1) {
          this.order_addGet(1);
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    addressShowDelJs(data) {
      this.addressshow = false;
      if (data) {
        this.addresslist = [];
        this.addressList();
      }
    },
    addresslistClick(item, i) {
      this.addressInfo = item;
      this.addressOn = i;
    },
    address_default_get() {
      this.$get("/web/address_default_get").then((res) => {
        this.addressInfo = res.data;
      });
    },
    addressList() {
      this.$get("/web/address", {
        page: -1,
      }).then((res) => {
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            this.addresslist.push(e);
          }
        }
      });
    },
    evaluation_gets() {
      this.$get("/web/evaluation_gets", {
        pageNo: 1,
        pageSize: 4,
        id: this.id,
      }).then((res) => {
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.time = this.timeago(e.create_time);
          }
          this.yonghu = res.data;
        }
      });
    },
    timeago(dateTimeStamp) {
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let week = day * 7;
      let month = day * 30;
      let time1 = new Date().getTime(); //当前的时间戳
      dateTimeStamp = this.$moment(dateTimeStamp).format("YYYY/M/D HH:mm");
      let time2 = Date.parse(new Date(dateTimeStamp)); //指定时间的时间戳
      let time = time1 - time2;
      let result = null;
      if (time < 0) {
        result = "--";
      } else if (time / month >= 1) {
        result = parseInt(time / month) + "月前";
      } else if (time / week >= 1) {
        result = parseInt(time / week) + "周前";
      } else if (time / day >= 1) {
        result = parseInt(time / day) + "天前";
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + "小时前";
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
    shopClick() {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      this.visible = true;
    },
    order_addGet(type) {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      if (!this.addressInfo.id) {
        this.$message.warning("请先添加地址！");
        this.addressshow = true
        this.visible = false
        return;
      }
      this.$post("/web/order_add", {
        id: Number(this.id),
        address_id: this.addressInfo.id,
        buynow: type,
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message.success(res.data.msg);
          this.$router.push({
            path: "/orderCont",
            query: {
              id: res.data.data,
            },
          });
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    gwcClick() {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      let list = [];
      let spec_id = "";
      let data = {};
      if (this.moPdList) {
        for (let i = 0; i < this.attrList.length; i++) {
          const e = this.attrList[i];
          if (e.currentValue) {
            list.push(e.currentValue);
          }
        }
        list = list.join("_");
        for (let i = 0; i < this.moPdList.length; i++) {
          const e = this.moPdList[i];
          if (e.name == list) {
            spec_id = e.id;
          }
        }
        data.spec_id = spec_id;
      }
      console.log(this.moPdList);
      if (this.moPdList.length != 0 && !spec_id) {
        this.$message.warning("请选择规格");
        return;
      }
      data.part_id = Number(this.id);
      data.num = this.num;
      this.$post("/web/car_add", data).then((res) => {
        if (res.data.status == 1) {
          this.gwNum = this.num;
          this.$message.success("加入购物车成功");
          this.$router.push({
            path: "/cart",
            query: {},
          });
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    handleCheck(rowIndex, colIndex) {
      const attrItem = this.attrList[rowIndex];
      attrItem.options.forEach((item, index) => {
        if (index === colIndex) {
          if (item.isChecked) {
            item.isChecked = false;
            attrItem.currentValue = null;
          } else {
            item.isChecked = true;
            attrItem.currentValue = item.value;
          }
        } else {
          item.isChecked = false;
        }
      });
      this.attrList = setAttrOptionStatus(this.attrList, this.skuMap);
      let quedTxt = []
      for (let i = 0; i < this.attrList.length; i++) {
        const e = this.attrList[i];
        quedTxt.push(e.currentValue)
      }
      quedTxt = quedTxt.join('_')
      for (let i = 0; i < this.moPdList.length; i++) {
        const e = this.moPdList[i];
        if (e.name==quedTxt) {
         this.info.price = e.price
         console.log(this.info.price); 
        }
      }
      this.$forceUpdate();
    },
    get() {
      this.$get("/web/part_get", {
        id: this.id,
      }).then((res) => {
        console.log(res.data);
        res.data.sales = this.roundToNearestTen(res.data.sales);
        this.info = res.data;
        this.resources = res.data.resources;
        this.infoImg = res.data.resources[0].path_name;
        let ggList = [];
        if (res.data.speciationList) {
          var speciationList = JSON.parse(res.data.speciationList);
          for (let i = 0; i < speciationList.length; i++) {
            const e = speciationList[i];
            if (e.checked) {
              e.xuanding = true;
              e.panduan = [];
              ggList.push(e);
            }
          }
        }
        this.ggList = ggList;
        // 获取对应规格的判断数据
        let moList = [];
        let moPdList = [];
        if (res.data.specs) {
          for (let i = 0; i < res.data.specs.length; i++) {
            const e = res.data.specs[i];
            e.spec = JSON.parse(e.spec);
            let txt = [];
            for (const j in e.spec) {
              if (Object.hasOwnProperty.call(e.spec, j)) {
                const ej = e.spec[j];
                if (j != "name") {
                  txt.push(ej);
                }
              }
            }
            let text = txt.join("_");
            moList.push(text);
            moPdList.push({
              name: text,
              price:e.price,
              id: e.id,
            });
          }
        }
        this.moPdList = moPdList;
        let attrList = [];
        for (let i = 0; i < ggList.length; i++) {
          const e = ggList[i];
          let data = {
            attrLabel: e.name,
            options: e.child,
          };
          attrList.push(data);
        }
        this.attrList = attrList;
        this.skuList = createSkuList(this.attrList);
        this.skuList = handleQuickSetSkuStock(this.skuList, moList);
        this.skuMap = computedSkuResultMap(this.skuList);
        this.attrList = setAttrOptionStatus(this.attrList, this.skuMap);
        this.specs = res.data.specs;
      });
    },
    ggClick(di, ci, item, chi) {
      if (chi.pd) {
        return;
      }
      // console.log(i, ci, item, chi);
      let propType = item.propType;
      for (let i = 0; i < this.ggList.length; i++) {
        const e = this.ggList[i];
        e.xuanding = false;
        e.newSel = [];
      }
      this.ggList[di].xuanding = true;
      let list = [];
      for (let i = 0; i < this.specs.length; i++) {
        const e = this.specs[i].spec;
        if (e[propType] == chi.value) {
          for (const j in e) {
            if (Object.hasOwnProperty.call(e, j)) {
              const element = e[j];
              for (let x = 0; x < this.ggList.length; x++) {
                const ggE = this.ggList[x];
                if (ggE.propType == j) {
                  ggE.newSel.push(element);
                }
              }
            }
          }
          list.push(e);
        }
      }
      for (let i = 0; i < this.ggList.length; i++) {
        const e = this.ggList[i];
        e.newSel = e.newSel.filter(
          (obj, index, self) => index === self.findIndex((t) => t === obj)
        );
      }
      for (let i = 0; i < this.ggList.length; i++) {
        const e = this.ggList[i];
        if (!e.xuanding) {
          e.panduan = e.panduan.map((item1) => {
            const match = e.newSel.find((el) => item1.name == el);
            if (!match) {
              return { ...item1, newPd: true };
            } else {
              if (item.select != undefined) {
                return { ...item1, newPd: false };
              } else {
                if (!item1.newPd) {
                  return { ...item1, newPd: false };
                } else {
                  return { ...item1, newPd: true };
                }
              }
            }
          });
        }
      }
      for (let i = 0; i < this.ggList.length; i++) {
        const e = this.ggList[i];
        if (!e.xuanding) {
          e.child = e.child.map((item) => {
            const match = e.panduan.find(
              (el) => item.value == el.name && !el.newPd
            );
            if (!match) {
              return { ...item, pd: true };
            } else {
              return { ...item, pd: false };
            }
          });
        }
      }
      if (ci != this.ggList[di].select) {
        this.$set(this.ggList[di], "select", ci);
        this.dianjiNum++;
      } else {
        this.$set(this.ggList[di], "select", null);
        this.dianjiNum--;
      }
      this.$forceUpdate();
    },
    roundToNearestTen(num) {
      if (num <= 10) {
        return 10;
      } else {
        // 获取数字的整数部分
        let intPart = Math.floor(Math.abs(num));
        // 判断个位数是否大于或等于5，决定是向上还是向下舍入
        if (intPart % 10 >= 5) {
          // 如果大于或等于5，则向上舍入
          intPart += 10 - (intPart % 10);
        } else {
          // 如果小于5，则直接向下舍入整十
          intPart -= intPart % 10;
        }
        // 处理负数和保留原始符号
        return (num < 0 ? -intPart : intPart) * Math.sign(num);
      }
    },
    searchClick() {
      window.open("/chuantian/#/shopList?name=" + this.seachName);
    },
    jianClick() {
      if (this.num > 1) {
        this.num--;
      } else {
        // this.$message.error("产品数量不能小1件");
      }
    },
    addClick() {
      this.num++;
    },
    handleBlur() {
      if (isNaN(this.num)) {
        this.$message.error("请输入数字");
        this.num = 1;
      } else if (this.num < 1) {
        this.$message.error("产品数量不能小1件");
        this.num = 1;
      }
    },
    dzShowJs() {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      this.dzShow = true;
      this.xuanzhuan = "xuanzhuan90";
    },
    dzDelJs() {
      this.dzShow = false;
      this.xuanzhuan = "xuanzhuan-90";
    },
    plClick() {
      this.plShow = true;
    },
    plDelClick() {
      this.plShow = false;
    },
    imgClick(list, i) {
      this.imgList = list;
      this.imgIndex = i;
      this.imgShow = true;
    },
    imgDelClick() {
      this.imgShow = false;
    },
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
      if (scrollTop < 650) {
        if (this.pd == 1) {
          this.cpShow = "xianshi";
          this.fuShow = "yincang";
        }
      } else {
        this.pd = 1;
        this.cpShow = "yincang";
        this.fuShow = "fuxianshi";
      }
      let navIndex = 0;
      for (let n = 0; n < this.offsetTopArr.length; n++) {
        if (scrollTop >= this.offsetTopArr[n] - 0) {
          // 下面介绍为啥-200
          navIndex = n;
        }
      }
      if (this.scopd == 0) {
        this.typeOn = navIndex;
      }
    },
    mouseover(i, path) {
      this.imgOn = i;
      this.infoImg = path;
    },
    typeClick(item, i) {
      this.scopd = 1;
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        this.scopd = 0;
      }, 1000);
      let anchor = this.$el.querySelectorAll("#section");
      let targetOffsetTop = this.offsetTopArr[i];
      this.typeOn = i;
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > targetOffsetTop) {
        // 判断需要往上滚动还是往下滚动;
        smoothUp();
      } else {
        smoothDown();
      }
      const STEP = 50;
      function smoothDown() {
        if (scrollTop < targetOffsetTop - 0) {
          // 下面介绍为啥-200
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          // 给页面的滚动距离重新设置，然后调用方法是页面平滑滚动
          window.scroll({
            top: scrollTop - 0,
            left: 0,
            behavior: "smooth",
          });
          requestAnimationFrame(smoothDown);
        }
      }
      // 由于上面我们-200的头部导航距离，所以这里我们需要减少一定的值，由于在该项目上一开始-200会导致定位有点怪异，-200却比较正常
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          window.scroll({
            top: scrollTop - 0,
            left: 0,
            behavior: "smooth",
          });
          requestAnimationFrame(smoothUp);
        }
      }

      // document.body.scrollTop = anchor.offsetTop
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .contentBox {
    width: 1440px;
    margin: auto;
    box-sizing: border-box;
  }
}
.shopHeadBox {
  display: flex;
  align-items: center;
  height: 75px;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  .img {
    height: 45px;
  }
  .inpBox {
    width: 300px;
    height: 40px;
    border: 1px solid #ebebeb;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    input {
      width: 100%;
      height: 100%;
      padding-left: 15px;
    }
    .searchBtn {
      cursor: pointer;
      width: 60px;
      height: calc(100% - 4px);
      position: absolute;
      right: 2px;
      top: 2px;
      background-color: rgb(233, 7, 7);
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.nrCont {
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
  position: relative;
  .nrHead {
    overflow: hidden;
    .leftBox {
      float: left;
      width: 50%;
      padding-right: 30px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      .imgs {
        width: 95px;
        height: 560px;
        padding-right: 10px;
        overflow: auto;
        .img {
          height: 100px;
          background-color: whitesmoke;
          margin-bottom: 15px;
          cursor: pointer;
          box-sizing: border-box;
          img {
            object-fit: contain;
          }
        }
        .img:hover {
          border: 1px solid #e70303;
        }
        .on {
          border: 1px solid #e70303;
        }
        .img:last-child {
          margin-bottom: 0;
        }
      }
      .imgBig {
        width: calc(100% - 115px);
        height: 560px;
        margin-left: 20px;
        background-color: whitesmoke;
        border-radius: 10px;
        overflow: hidden;
        img {
          object-fit: contain;
          border-radius: 5px;
        }
      }
    }
    .rightBox {
      padding-left: 10px;
      box-sizing: border-box;
      width: 50%;
      float: left;
      .rtitle {
        font-weight: 600;
        font-size: 20px;
      }
      .rtxt {
        color: #7c889c;
        margin-top: 12px;
      }
      .money {
        display: flex;
        align-items: flex-end;
        color: #e70303;
        margin-top: -5px;
        .num {
          font-weight: 600;
          font-size: 28px;
          position: relative;
          top: 6px;
        }
      }
      .dzBox {
        position: relative;
        margin-top: 10px;
        width: 100%;
        .dzCont {
          width: calc(100% - 230px);
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0 0 4px 2px #ebebeb;
          z-index: 9;
          padding: 10px;
          border-radius: 10px;
          background-color: #fff;
          .dzHead {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 10px;
            .name {
              font-weight: 600;
            }
            .iconfont {
              font-size: 14px;
              font-weight: 600;
              position: relative;
              top: 1px;
              cursor: pointer;
            }
          }
          .dzList {
            overflow: auto;
            height: 180px;
            padding-right: 5px;
            .dzLi {
              float: left;
              width: calc(50% - 7.5px);
              margin-right: 15px;
              margin-bottom: 15px;
              height: 80px;
              box-sizing: border-box;
              border-radius: 8px;
              background-color: #f3f6f8;
              padding: 12px 12px 9px;
              margin-right: 12px;
              font-size: 12px;
              position: relative;
              border: 1px solid transparent;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .mr {
                position: absolute;
                top: 4px;
                right: 4px;
                font-size: 12px;
                line-height: 12px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.2);
                padding: 4px;
                border-radius: 4px;
              }
              .name {
                font-weight: 600;
                span {
                  margin-left: 5px;
                  color: #9c9c9c;
                  font-size: 400;
                }
              }
              .txt {
                color: #7c889c;
              }
              .dzLiBox {
                width: 100%;
                height: 100%;
              }
            }
            .dzLi:hover {
              background-color: #fff;
              border: 1px solid #ff0800;
            }
            .on {
              background-color: #fff;
              border: 1px solid #ff0800;
            }
            .dzLi:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
      .box {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .name {
          width: 60px;
        }
        .text {
          width: calc(100% - 65px);
          padding-left: 10px;
          box-sizing: border-box;
          display: flex;
          .wz {
            cursor: pointer;
            display: flex;
            align-items: center;
            .iconfont {
              margin-left: 3px;
              font-size: 12px;
            }
            .xuanzhuan90 {
              animation: zhuan90 0.5s both;
            }
            @keyframes zhuan90 {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(-180deg);
              }
            }
            .xuanzhuan-90 {
              animation: zhuan-90 0.5s both;
            }
            @keyframes zhuan-90 {
              0% {
                transform: rotate(-180deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }
          }
          .zgi {
            margin: 0 10px;
          }
        }
        .numBox {
          border: 1px solid #ebebeb;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
        margin-top: 10px;
        display: flex;
        /deep/.ant-form-item-label {
          width: 60px;
          text-align: left;
          // text-align-last: justify;
        }
        /deep/.ant-form-item-control-wrapper {
          width: calc(100% - 65px);
          padding-left: 10px;
          box-sizing: border-box;
        }
      }
      .box1 {
        display: flex;
        margin-top: 15px;
        .name {
          text-align-last: justify;
          width: 60px;
          margin-top: 4px;
        }
        .txt {
          margin-top: 4px;
        }
        .txts {
          width: calc(100% - 65px);
          padding-left: 10px;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          .text {
            border: 1px solid #d6d6d6;
            padding: 5px 15px;
            font-size: 12px;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            box-sizing: border-box;
          }
          .on {
            color: #e70303;
            border-color: #e70303;
          }
          .hui {
            color: #fff;
            border-color: #c9c9c9;
            background-color: #c9c9c9;
            cursor: not-allowed;
          }
        }
      }
      .box2 {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .name {
          // text-align-last: justify;
          width: 60px;
        }
        .text {
          width: calc(100% - 65px);
          padding-left: 10px;
          box-sizing: border-box;
          display: flex;
          .wz {
            cursor: pointer;
            span {
              margin-left: 3px;
              font-size: 12px;
            }
          }
          .zgi {
            margin: 0 10px;
          }
        }
        .numBox {
          border: 1px solid #ebebeb;
          height: 30px;
          margin-left: 10px;
          border-radius: 5px;
          width: 102px;
          overflow: hidden;
          .an {
            width: 30px;
            border-right: 1px solid #ebebeb;
            height: 100%;
            float: left;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 14px;
          }
          .jy {
            cursor: not-allowed;
          }
          .num {
            float: left;
            width: 40px;
            height: 100%;
            input {
              width: 100%;
              height: 100%;
              text-align: center;
            }
          }
          .an1 {
            width: 30px;
            border-left: 1px solid #ebebeb;
            height: 100%;
            float: left;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
      .funBox {
        margin-top: 25px;
        height: 50px;
        border-radius: 10px;
        overflow: hidden;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        width: 360px;
        cursor: pointer;
        .fun {
          display: flex;
          width: 50%;
          float: left;
          height: 100%;
          align-items: center;
          justify-content: center;
          background: linear-gradient(90deg, rgb(255, 119, 0), rgb(255, 73, 0));
        }
        .fun1 {
          display: flex;
          width: 50%;
          float: left;
          height: 100%;
          align-items: center;
          justify-content: center;
          background: linear-gradient(
            90deg,
            rgb(255, 203, 0),
            rgb(255, 148, 2)
          );
        }
      }
    }
  }
  .typeList {
    height: 65px;
    display: flex;
    box-sizing: border-box;
    border-bottom: 2px solid whitesmoke;
    position: sticky;
    background-color: #fff;
    margin-top: 10px;
    top: 55px;
    z-index: 99;
    .type {
      height: 100%;
      box-sizing: border-box;
      margin-right: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 2px;
      cursor: pointer;
    }
    .on {
      border-bottom: 2px solid #e70303;
      color: #e70303;
      font-weight: 600;
    }
  }
  .nrContent {
    margin-top: 30px;
    .nrtitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      color: #11192d;
      margin-bottom: 20px;
    }
    .pjList {
      box-sizing: border-box;
      width: calc(100% - 480px);
      .pjLi {
        display: flex;
        margin-bottom: 15px;
        .img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
        }
        .pjLiBox {
          width: calc(100% - 60px);
          margin-left: 15px;
          box-sizing: border-box;
          border-bottom: 1px solid #ebebeb;
          .pjHead {
            height: 45px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .name {
              color: #11192d;
            }
            .txt {
              color: #7c889c;
              display: flex;
              align-items: center;
              .d {
                margin: 0 5px;
              }
              .star {
                margin-top: 1px;
                display: flex;
                .iconfont {
                  color: #e70303;
                }
              }
            }
          }
          .pjTxt {
            margin-bottom: 10px;
            margin-top: 5px;
          }
          .pjImgs {
            overflow: hidden;
            .pjimg {
              float: left;
              width: calc(20% - 8px);
              margin-right: 10px;
              margin-bottom: 10px;
              padding-top: calc(20% - 8px);
              position: relative;
              cursor: pointer;
              img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
              }
            }
            .pjimg:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }
    }
    .pjAnBox {
      width: calc(100% - 480px);
      display: flex;
      justify-content: center;
    }
    .pjAn {
      cursor: pointer;
      width: 144px;
      height: 40px;
      background: #fff;
      border: 1px solid #e2e4e6;
      border-radius: 4px;
      // font-size: 16px;
      color: #11192d;
      letter-spacing: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 14px;
      }
    }
    .csCont {
      border: 1px solid #f0f3f5;
      width: calc(100% - 480px);
      border-radius: 5px;
      overflow: hidden;
      box-sizing: border-box;
      .csLi {
        height: 60px;
        width: 50%;
        border-bottom: 1px solid #f0f3f5;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        float: left;
        border-right: 1px solid #f0f3f5;
        .name {
          background-color: #f3f6f8;
          width: 160px;
          height: 100%;
          box-sizing: border-box;
          padding-left: 20px;
          color: #11192d;
          display: flex;
          align-items: center;
        }
        .text {
          width: calc(100% - 160px);
          height: 100%;
          box-sizing: border-box;
          padding-left: 20px;
          display: flex;
          align-items: center;
        }
      }
      .csLi:nth-child(2n) {
        border-right: none;
      }
    }
    .twCont {
      width: calc(100% - 480px);
      box-sizing: border-box;
    }
  }
  .fuCont {
    width: 400px;
    height: calc(100vh - 200px);
    border: 1px solid #ebebeb;
    position: fixed;
    bottom: 40px;
    right: 0;
    border-radius: 10px;
    background-color: #fff;
    display: none;
    opacity: 0;
    .fuBox {
      padding: 15px 5px 0 15px;
      box-sizing: border-box;
      height: calc(100% - 80px);
      border-bottom: 1px solid #ebebeb;
      width: 100%;
      .fuBoxHead {
        height: 90px;
        margin-bottom: 15px;
        display: flex;
        .img {
          height: 90px;
          width: 90px;
          img {
            object-fit: cover;
          }
        }
        .box {
          width: calc(100% - 90px);
          padding-left: 15px;
          height: 90px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .money {
            color: #f05011;
            display: flex;
            align-items: flex-end;
            .txt {
              position: relative;
              top: -3px;
            }
            .num {
              font-weight: 600;
              font-size: 24px;
            }
          }
        }
      }
      .fuBoxList {
        height: calc(100% - 115px);
        margin-bottom: 10px;
        width: 100%;
        overflow: auto;
        .fuBoxLi {
          overflow: hidden;
          margin-bottom: 10px;
          /deep/.ant-form-item-label {
            line-height: 25px;
          }
          .ant-form-item {
            margin-bottom: 10px;
          }
          .nameBox {
            display: flex;
            float: left;
            align-items: center;
            width: 65px;
            .name {
              // width: 60px;
              text-align-last: justify;
              margin-top: 6px;
            }
            .txt {
              width: 5px;
            }
          }
          .boxs {
            float: left;
            width: calc(100% - 65px);
            box-sizing: border-box;
            padding-left: 15px;
            padding-right: 10px;
            .box {
              height: 35px;
              border: 1px solid #dadde0;
              display: flex;
              align-items: center;
              border-radius: 5px;
              padding-left: 10px;
              box-sizing: border-box;
              margin-bottom: 15px;
            }
          }
          .numBox {
            border: 1px solid #ebebeb;
            height: 30px;
            margin-left: 15px;
            border-radius: 5px;
            width: 102px;
            float: left;
            overflow: hidden;
            .an {
              width: 30px;
              border-right: 1px solid #ebebeb;
              height: 100%;
              float: left;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              font-size: 14px;
            }
            .num {
              float: left;
              width: 40px;
              height: 100%;
              input {
                width: 100%;
                height: 100%;
                text-align: center;
              }
            }
            .an1 {
              width: 30px;
              border-left: 1px solid #ebebeb;
              height: 100%;
              float: left;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
        }
      }
    }
    .fuBtn {
      height: 80px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 15px;
      .box {
        height: 50px;
        flex: 1;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, rgb(255, 119, 0), rgb(255, 73, 0));
        border-radius: 10px;
        font-weight: 600;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
      .box:last-child {
        margin-right: 0;
        background: linear-gradient(90deg, rgb(255, 203, 0), rgb(255, 148, 2));
      }
    }
  }
  .xianshi {
    animation: xianshi 1.5s both;
  }
  @keyframes xianshi {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .yincang {
    opacity: 0;
  }
  .fuxianshi {
    animation: fuxianshi 0.6s both;
    display: block;
  }
  @keyframes fuxianshi {
    0% {
      bottom: -20px;
      opacity: 0;
      box-shadow: 0 0 0 0 rgba(0, 17, 51, 0.06);
    }
    100% {
      bottom: 40px;
      opacity: 1;
      box-shadow: 0 6px 48px 0 rgba(0, 17, 51, 0.06);
    }
  }
}
</style>
